<template>
  <div class="worker-tab-inner">

    <!-- Umowy-->
    <b-row>

      <b-col
        cols="12"
      >

        <b-card
          body-class="pb-50"
        >
          <h3 class="mb-1">
            Umowy
          </h3>
          <p>Brak umów pracownika</p>

          <!-- <b-link>deklaracja-zus-imir.pdf</b-link> -->
          <div class="mb-1" />

        </b-card>

      </b-col>

    </b-row>

    <!-- Rachunki oczekujące na opłacenie-->
    <b-row>

      <b-col
        cols="12"
      >

        <b-card
          body-class="pb-50"
        >
          <h3 class="mb-1">
            Rachunki oczekujące na opłacenie
          </h3>

          <p>Brak rachunków oczekujących na opłacenie</p>
          <div class="mb-1" />

        </b-card>

      </b-col>

    </b-row>

  </div>
</template>

<script>

import {
  BRow, BCol, BCard, BLink,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BLink,
  },
}
</script>

<style lang="scss" scoped>

.bg-white {
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  background-color: #fff;
  border-radius: 0.428rem;
  transition: all 0.3s ease-in-out, background 0s, color 0s, border-color 0s;
}

</style>
