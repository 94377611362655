<template>

  <div class="single-worker--inner">
    <div class="single-worker--header p-5 bg-white mb-2">
      <b-row>

        <b-col
          cols="12"
          lg="6"
        >
          <h1>{{ user.name }}</h1>
          <p>{{ user.email }}  | {{ user.phone }} </p>
        </b-col>

        <b-col
          cols="12"
          lg="6"
          class="d-flex  align-items-center justify-content-end"
        >

          <b-dropdown
            id="dropdown-1"
            text="Dodaj umowę"
            variant="primary"
            class="ml-1"
          >

            <b-dropdown-item>
              <router-link :to="{name: 'apps-contracts-add' , params: { contractType: 'classicContract'} }">
                Nowa umowa o pracę
              </router-link>
            </b-dropdown-item>
            <b-dropdown-item>
              <router-link :to="{name: 'apps-contracts-add' , params: { contractType: 'contractWork'} }">
                Nowa umowa o dzieło
              </router-link>
            </b-dropdown-item>
            <b-dropdown-item>
              <router-link :to="{name: 'apps-contracts-add' , params: { contractType: 'mandateContract'} }">
                Nowa umowa zlecenie
              </router-link>
            </b-dropdown-item>

          </b-dropdown>
        </b-col>

      </b-row>
    </div>
    <b-tabs
      vertical
      content-class="col-12 col-md-9 mt-1 mt-md-0"
      pills
      nav-wrapper-class="col-md-3 col-12"
      nav-class="nav-left"
    >

      <!-- Profil pracownika -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Profil pracownika</span>
        </template>

        <WorkerProfile />
      </b-tab>

      <!-- Role -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Role</span>
        </template>

        <RoleEditor
          :user-data="user"
        />
      </b-tab>

      <!-- Dane pracownika-->
      <b-tab>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Dane pracownika</span>
        </template>

        <WorkerInfo />
      </b-tab>

      <!-- Deklaracje i formularze-->
      <b-tab>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Deklaracje i formularze</span>
        </template>

        <WorkerDeclarations />
      </b-tab>

      <!-- Umowy i rachunki-->
      <b-tab>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Umowy i rachunki</span>
        </template>

        <WorkerContractsAndBills />
      </b-tab>

      <!-- Listy płac-->
      <b-tab>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Listy płac</span>
        </template>

        <FullPayrollList />
      </b-tab>

    </b-tabs>
  </div>

</template>

<script>
import {
  BTabs, BTab, BCol, BRow, BDropdown, BDropdownItem,
} from 'bootstrap-vue'

// tabs
import axios from 'axios'
import Swal from 'sweetalert2'
// eslint-disable-next-line import/extensions
import RoleEditor from '@/views/apps/user/users-edit/RoleEditor'
import WorkerProfile from './tabs/WorkerProfile.vue'
import WorkerInfo from './tabs/WorkerInfo.vue'
import WorkerDeclarations from './tabs/WorkerDeclarations.vue'
import WorkerContractsAndBills from './tabs/WorkerContractsAndBills.vue'
import FullPayrollList from './tabs/FullPayrollList.vue'

export default {
  components: {
    RoleEditor,
    BTabs,
    BTab,
    BCol,
    BRow,
    BDropdown,
    BDropdownItem,
    WorkerProfile,
    WorkerInfo,
    WorkerDeclarations,
    WorkerContractsAndBills,
    FullPayrollList,

  },
  data() {
    return {
      name: '',
      email: '',
      tel: '',
      type: '',
      user: {},
    }
  },
  mounted() {
    const token = localStorage.getItem('accessToken')

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    axios.get(`${process.env.VUE_APP_API_URL}User/${this.$route.params.id}`, config)
      .then(response => {
        this.name = response.data.user.name
        this.email = response.data.user.email
        this.tel = response.data.user.tel
        this.type = response.data.user.type
        this.user.name = response.data.user.name
        this.user.email = response.data.user.email
        this.user.tel = response.data.user.tel
        this.user.type = response.data.user.type
      })
      .catch(error => {
        console.log(error)
        Swal.fire(
          'Coś poszło nie tak!',
          '',
          'error',
        )
      })
      try{
        axios.get(`${process.env.VUE_APP_API_URL}employee/${this.$route.params.id}`, config)
          .then(response => {
            this.user = response.data
          })
      }
      catch(error){
        console.log(error)
        Swal.fire(
          'Coś poszło nie tak!',
          '',
          'error',
        )
      }
  },

}
</script>
